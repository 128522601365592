.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #0b2240;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #0b2240;
}

.gutterBottomHalf {
  margin-bottom: 8px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mdl-button--raised.mdl-button--colored {
  background: #e80a4d !important;
  width: 100%;
  margin-left: 0;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after,
.mdl-textfield.is-focused .mdl-textfield__label:after,
.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  color: #0b2240 !important;
  background-color: #0b2240 !important;
  bottom: 20px;
}

.firebaseui-subtitle,
.firebaseui-title {
  color: #0b2240 !important;
  font-weight: 900;
  text-align: center !important;
}

.StripeElement {
  width: 100%;
  padding: 20px 12px 20px 12px;
  background-color: #e0e0e0;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px dotted #333;
}

.breathing {
  width: 55px;
  height: 55px;
  margin: 8px auto 0;
  // -webkit-animation: breathing 2s ease-out infinite normal;
  // animation: breathing 2s ease-out infinite normal;
  -webkit-animation: 1.5s infinite beatHeart;
  animation: 1.5s infinite beatHeart;
  // -webkit-animation: sk-scaleout 1.3s infinite ease-in-out;
  // animation: sk-scaleout 1.3s infinite ease-in-out;
  // -webkit-transform: translateZ(0);
  // -ms-transform: translateZ(0);
  // transform: translateZ(0);
  // -webkit-animation: round 1.7s infinite ease;
  // animation: round 1.7s infinite ease;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes beatHeart {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  40% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Page transition
// Sections
.page-takeover {
  height: 100%;
  width: 100%;
  display: table;
  pointer-events: none;
  z-index: 0;
  -webkit-transition: transform 0.45s cubic-bezier(0, 0, 0.21, 1);
  transition: transform 0.45s cubic-bezier(0, 0, 0.21, 1);

  h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-weight: lighter;
  }
}

#one {
  background: #2196f3;
}

#two {
  background: #00bcd4;
}

#three {
  background: #673ab7;
}

// Smooth Scroll
a[id='one']:target ~ #one {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

a[class='newPage']:target ~ #two {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

a[id='three']:target ~ #three {
  -webkit-transform: translate3d(0, -200%, 0);
  transform: translate3d(0, -200%, 0);
}

// CSS Transition
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.page-overlay {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  flex: 1;
  max-width: 100%;
  box-sizing: border-box;
}
