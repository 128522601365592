body {
  margin: 0;
  // padding: 20px 20px 20px 20px;
  padding: 0px;
  // width: 100%;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: apple-system, BlinkMacSystemFont, Roboto, Arial, 'Helvetica Neue',
    Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  display: block;
  margin-bottom: 10px;
}

input {
  margin-left: 10px;
}

img {
  width: 100%;
}

p,
ul,
li,
ol {
  color: #3b4e66;
}
